import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eff9ed36"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  slot: "label",
  class: "ion-text-wrap"
}
const _hoisted_2 = {
  slot: "label",
  class: "ion-text-wrap"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "actions mobile-only" }
const _hoisted_5 = { class: "more-actions" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", null, [
      _createVNode(_component_ion_item, { lines: "none" }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_ctx.isBrokerJob ? _ctx.currentJob.jobName : _ctx.currentJob.enumName ? _ctx.currentJob.enumName : _ctx.currentJob.jobName ? _ctx.currentJob.jobName : ''), 1),
          (_ctx.isRefreshRequired)
            ? (_openBlock(), _createBlock(_component_ion_button, {
                key: 0,
                fill: "outline",
                slot: "end",
                onClick: _ctx.refreshCurrentJob
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.refreshOutline,
                    slot: "icon-only"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.currentJob.cancelDateTime || _ctx.currentJob.finishDateTime)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 1,
                slot: "end",
                color: "dark"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.currentJob.statusId == "SERVICE_CANCELLED" || _ctx.currentJob.statusId == "SERVICE_CRASHED" ?  _ctx.timeTillJob(_ctx.currentJob.cancelDateTime) : _ctx.timeTillJob(_ctx.currentJob.finishDateTime)), 1)
                ]),
                _: 1
              }))
            : (_ctx.currentJob?.runTime && _ctx.currentJob.statusId !== 'SERVICE_DRAFT' && !_ctx.isRefreshRequired)
              ? (_openBlock(), _createBlock(_component_ion_badge, {
                  key: 2,
                  slot: "end",
                  color: "dark"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("running")) + " " + _toDisplayString(_ctx.timeTillJob(_ctx.currentJob.runTime)), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          (_ctx.currentJob.description)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 0,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.currentJob.description), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.timeOutline
              }, null, 8, ["icon"]),
              (_ctx.historyJobConfig)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Run time")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.currentJob.runTime ? _ctx.getTime(_ctx.currentJob.runTime) : ''), 1)
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_ion_select, {
                      interface: "popover",
                      placeholder: _ctx.translate('Select'),
                      value: _ctx.runTime,
                      onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateRunTime($event)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.translate("Run time")), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.runTimes, (runTime) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: runTime.value,
                            value: runTime.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate(runTime.label)), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["placeholder", "value"]),
                    _createVNode(_component_ion_modal, {
                      class: "date-time-modal",
                      "is-open": _ctx.isDateTimeModalOpen,
                      onDidDismiss: _cache[2] || (_cache[2] = () => _ctx.isDateTimeModalOpen = false)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_content, { "force-overscroll": "false" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_datetime, {
                              "show-default-buttons": "",
                              "hour-cycle": "h23",
                              value: _ctx.runTime ? (_ctx.isCustomRunTime(_ctx.runTime) ? _ctx.getDateTime(_ctx.runTime) : _ctx.getDateTime(_ctx.DateTime.now().toMillis() + _ctx.runTime)) : _ctx.getNowTimestamp(),
                              onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCustomTime($event)))
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["is-open"])
                  ], 64))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.timerOutline
              }, null, 8, ["icon"]),
              (_ctx.historyJobConfig)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Schedule")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.currentJob.tempExprId ? _ctx.temporalExpr(_ctx.currentJob.tempExprId)?.description : "🙃"), 1)
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createBlock(_component_ion_select, {
                    key: 1,
                    value: _ctx.jobStatus,
                    "interface-options": { header: _ctx.translate('Frequency') },
                    interface: "popover",
                    placeholder: _ctx.translate('Disabled'),
                    onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.jobStatus = $event.detail.value)),
                    onIonDismiss: _cache[4] || (_cache[4] = ($event: any) => (_ctx.jobStatus == 'CUSTOM' && _ctx.setCustomFrequency()))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate("Schedule")), 1),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frequencyOptions, (freq) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: freq.id,
                          value: freq.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(freq.description), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "interface-options", "placeholder"]))
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, { lines: "none" }, {
            default: _withCtx(() => [
              (!Object.keys(_ctx.generateCustomParameters).length)
                ? (_openBlock(), _createBlock(_component_ion_chip, {
                    key: 0,
                    onClick: _ctx.openJobCustomParameterModal,
                    outline: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate('Add custom parameters')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.generateCustomParameters, (value, name) => {
                        return (_openBlock(), _createBlock(_component_ion_chip, {
                          onClick: _ctx.openJobCustomParameterModal,
                          outline: "",
                          color: value ? undefined :'danger',
                          key: name
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(name) + ": " + _toDisplayString(value), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "color"]))
                      }), 128))
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_button, {
                onClick: _ctx.openJobCustomParameterModal,
                id: "open-modal",
                slot: "end",
                fill: "clear"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "icon-only",
                    icon: _ctx.listCircleOutline
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: "actions desktop-only",
        disabled: _ctx.historyJobConfig
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_ion_button, {
            size: "small",
            fill: "outline",
            color: "medium",
            disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.currentJob.statusId === 'SERVICE_DRAFT' || _ctx.isRefreshRequired || _ctx.historyJobConfig,
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.skipJob(_ctx.currentJob)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Skip once")), 1)
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_component_ion_button, {
            size: "small",
            fill: "outline",
            color: "danger",
            disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.currentJob.statusId === 'SERVICE_DRAFT' || _ctx.isRefreshRequired || _ctx.historyJobConfig,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.cancelJob(_ctx.currentJob)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Disable")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_ion_button, {
            disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.isRequiredParametersMissing || _ctx.isRefreshRequired || _ctx.historyJobConfig,
            size: "small",
            fill: "outline",
            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.saveChanges()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Save changes")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ], 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ion_button, {
          size: "small",
          expand: "block",
          fill: "outline",
          color: "medium",
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.status === 'SERVICE_DRAFT' || _ctx.isRefreshRequired || _ctx.historyJobConfig,
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.skipJob(_ctx.currentJob)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("Skip once")), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_ion_button, {
          size: "small",
          expand: "block",
          fill: "outline",
          color: "danger",
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.status === 'SERVICE_DRAFT' || _ctx.isRefreshRequired || _ctx.historyJobConfig,
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.cancelJob(_ctx.currentJob)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("Disable")), 1)
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_ion_button, {
          disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.isRequiredParametersMissing || _ctx.isRefreshRequired || _ctx.historyJobConfig,
          expand: "block",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.saveChanges()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("Save changes")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ion_item, {
        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.viewJobHistory(_ctx.currentJob))),
        button: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.timeOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("History")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        disabled: !_ctx.hasPermission(_ctx.Actions.APP_JOB_UPDATE) || _ctx.historyJobConfig,
        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.runNow(_ctx.currentJob))),
        button: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.flashOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("Run now")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_ion_item, {
        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.copyJobInformation(_ctx.currentJob))),
        button: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.copyOutline
          }, null, 8, ["icon"]),
          _createTextVNode(" " + _toDisplayString(_ctx.translate("Copy details")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item, {
        onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.updatePinnedJobs(_ctx.currentJob?.systemJobEnumId))),
        button: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            slot: "start",
            icon: _ctx.pinOutline
          }, null, 8, ["icon"]),
          _createVNode(_component_ion_checkbox, {
            checked: _ctx.pinnedJobs && _ctx.pinnedJobs.includes(_ctx.currentJob.systemJobEnumId)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Pin job")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["checked"])
        ]),
        _: 1
      })
    ]),
    (_ctx.historyJobConfig && _ctx.currentJob.runtimeData?.configId && _ctx.getDataManagerLogs?.length)
      ? (_openBlock(), _createElementBlock("section", _hoisted_6, [
          _createVNode(_component_ion_item, { lines: "none" }, {
            default: _withCtx(() => [
              _createElementVNode("h1", null, _toDisplayString(_ctx.translate('Import logs')), 1),
              _createVNode(_component_ion_button, {
                slot: "end",
                fill: "clear",
                onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.openImportLogsDetails()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('View details')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_progress_bar, {
            value: (_ctx.getProcessedFileCount() - _ctx.getErrorFileCount()) / _ctx.getDataManagerLogs.length
          }, null, 8, ["value"]),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.fileTrayFullOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate('Files received')) + " ", 1),
                  _createVNode(_component_ion_label, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getDataManagerLogs.length), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.codeWorkingOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate('Files processed')) + " ", 1),
                  _createVNode(_component_ion_label, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getProcessedFileCount()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, { lines: "none" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    slot: "start",
                    icon: _ctx.warningOutline
                  }, null, 8, ["icon"]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate('Files with errors')) + " ", 1),
                  _createVNode(_component_ion_label, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getErrorFileCount()), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}