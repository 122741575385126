import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_InitialJobConfiguration = _resolveComponent("InitialJobConfiguration")!
  const _component_JobConfiguration = _resolveComponent("JobConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": '/' + _ctx.jobCategory,
                slot: "start"
              }, null, 8, ["default-href"]),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Job details")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_ctx.jobCategory === 'initial-load')
            ? (_openBlock(), _createBlock(_component_InitialJobConfiguration, {
                type: _ctx.type,
                shopifyOrderId: _ctx.lastShopifyOrderId,
                key: _ctx.currentJob
              }, null, 8, ["type", "shopifyOrderId"]))
            : (_openBlock(), _createBlock(_component_JobConfiguration, {
                status: _ctx.currentJob?.status === 'SERVICE_DRAFT' ? _ctx.currentJob?.status : _ctx.currentJob?.tempExprId,
                type: _ctx.freqType,
                key: _ctx.currentJob,
                historyJobConfig: _ctx.checkJobStatus(_ctx.currentJob?.statusId)
              }, null, 8, ["status", "type", "historyJobConfig"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}